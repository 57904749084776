/*
============
=== PAGE ===
============
*/
body.arquetipo {
  background: url('../../../assets/img/bg_arquetipo-explorador.jpg') top center no-repeat;
  background-size: cover;
}

body.arquetipo .container {
  display: grid;
  grid-template: repeat(2, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.arquetipo-title {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #FFF;
  color: #F99122;
  text-align: center;
  margin-bottom: 4rem;
  border-radius: 0.8rem;
  padding: 2rem;
}
.arquetipo-title .title {
  color: #F99122;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.arquetipo-content {
  display: grid;
  grid-template: repeat(5, minmax(auto, 84px)) / repeat(2, minmax(auto, 464px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}

.arquetipo__content {
  grid-column: 1 / 3;
  grid-row: 1 / 6;
  background-color: #2B2A28;
  border-radius: 0.8rem;
  padding: 5.6rem 5.6rem 12rem 5.6rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.arquetipo__avatar {
  margin-top: -10rem;
  margin-bottom: 3rem;
}

.arquetipo__title {
  margin-bottom: 1.5rem;
}
.arquetipo__title .title {
  color: #F99122;
  text-transform: uppercase;
}

.arquetipo__text {
  margin-bottom: 1.5rem;
  color: #fff;
}
.arquetipo__text .text-featured {
  color: #DB3167;
}
.arquetipo__text .text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'mainFontBold';
}
.arquetipo .button__container {
  display: grid;
  grid-template: repeat(1, minmax(auto, 84px)) / repeat(4, minmax(auto, 224px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}
.arquetipo .arquetipo__feedback {
  background-color: transparent;
  grid-column: 1 / 5;
  border-radius: 0;
  text-align: right;
  padding-top: 2.5rem;
  padding-bottom: 0;
}
.arquetipo .arquetipo__feedback .feedback__text {
  font-family: 'mainFontBold';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-align: right;
  display: flex;
  justify-content: center;
  align-content: center;
}
