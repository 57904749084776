
/*
============
== FOOTER ==
============
*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2rem;
}
.footer .footer-item {
}

.footer .footer__links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer .footer__links .footer__copyright p {
  font-family: 'mainFont';
  font-size: 1.2rem;
  color: #475a73;
  letter-spacing: 0;
}
.footer .footer__links .footer__copyright p::after {
  content: '|';
  margin: 0 1rem 0 1rem;
}
.footer .footer__links .footer__copyright span {
  font-family: 'mainFontBold';  
}
.footer .footer__links .footer__legal a,
.footer .footer__links .footer__legal a:hover,
.footer .footer__links .footer__legal a:visited,
.footer .footer__links .footer__legal a:active {
  font-family: 'mainFont';
  font-size: 1.2rem;
  color: #475a73;
  letter-spacing: 0;
  display: block;
}
.footer .footer__logo {
}
.footer .footer__logo img {
}
