/*
============
=== PAGE ===
============
*/

body.questions .container {
  display: grid;
  grid-template: repeat(3, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.questions-content {
  display: grid;
  grid-template: repeat(2, minmax(auto, 226px)) / repeat(4, minmax(auto, 226px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.6rem;
}

.question__content {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  background: #ffffff;
  border-radius: 0.8rem;
  padding: 5.6rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: space-between;
}
.question__position {
  background-color: #DB3167;
  border-radius: 0.8rem;
  position: absolute;
  top: 5.6rem;
  left: -4rem;
  padding: 2.2rem;
}
.question__number {
  font-family: 'mainFont';
  font-size: 2.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.question__title {
  font-family: 'mainFontBold';
  font-size: 3.2rem;
  color: #F99122;
  letter-spacing: 0;
  text-align: right;
  line-height: 3.9rem;
}
.question__text {
  font-family: 'mainFontBold';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-align: right;
  line-height: 1.7rem;
}

.answer {
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(71, 90, 115, 0.4);
  border-radius: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;
}
.answer:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 20%;
  transition: transform 0.3s ease-out;
}
.answer:hover:before {
  transform: scaleX(1);
}
.answer:hover .answer__text {
  color: #fff;
}
.answer.multi:after {
  content: url('../../../assets/img/check-desmarcado.svg');
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 10%;
  left: auto;
  right: 10%;
}
.multi:hover:after {
  content: url('../../../assets/img/check-marcado.svg');
}
.multi.clicked:after {
  content: url('../../../assets/img/check-marcado.svg');
}

.answer__choice {
  width: 20%;
  height: 100%;
}
.answer__text {
  padding-left: 2%;
  padding-right: 10%;
  width: 80%;
  font-family: 'mainFont';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  line-height: 1.7rem;
}
.answer.clicked .answer__text {
  color: #fff;
}

.answer__a {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}
.answer__a .answer__choice {
  background: url('../../../assets/img/respuesta-a.svg') center left no-repeat;
}
.answer__a.clicked {
  background-color: #19bc9c;
}
.answer__a:before {
  background-color: #19bc9c;
}

.answer__b {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}
.answer__b .answer__choice {
  background: url('../../../assets/img/respuesta-b.svg') center left no-repeat;
}
.answer__b.clicked {
  background-color: #3498db;
}
.answer__b:before {
  background-color: #3498db;
}

.answer__c {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}
.answer__c .answer__choice {
  background: url('../../../assets/img/respuesta-c.svg') center left no-repeat;
}
.answer__c.clicked {
  background-color: #9b59b6;
}
.answer__c:before {
  background-color: #9b59b6;
}

.answer__d {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}
.answer__d .answer__choice {
  background: url('../../../assets/img/respuesta-d.svg') center left no-repeat;
}
.answer__d.clicked {
  background-color: #DBD234;
}
.answer__d:before {
  background-color: #DBD234;
}

.questions-feedback {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template: repeat(1, auto) / repeat(5, minmax(auto, 226px));
}
.question__feedback {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  background-color: #D21309;
  border-radius: 0.8rem;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.feedback__text {
  font-family: 'mainFontBold';
  font-size: 1.8rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.question__button {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
  margin-left: 2rem;
  display: flex;
}

.question__button .button {
  margin-right: 0;
  width: 100%;
}

.question__button .button .button__link {
  width: 100%;
}
