/*
============
=== PAGE ===
============
*/
.dashboard {
  font-family: 'mainFont';
  color: #000;
  background-color: #eee;
  display: grid;
  grid-template: 1fr / minmax(auto, 350px) minmax(auto, 1fr);
  justify-content: flex-start;
  align-content: flex-start;
  grid-gap: 0;
  min-height: 100vh;
}

.content {
  background-color: transparent;
  display: grid;
  grid-template: minmax(auto, 65px) / repeat(4, minmax(auto, 1fr));
  justify-content: flex-start;
  align-content: flex-start;
  grid-gap: 2rem;
  padding: 2rem;
}

/*
============
=== CARD ===
============
*/
section.dashboard-export {
  grid-column: 1 / 3;
}
.card {
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
  cursor: pointer;
}
.card .icon-section svg {
  color: #fff;
}
.card .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.card .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.card .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #2B2A28;
}
