/*
============
== NAVBAR ==
============
*/
.header-navbar {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
}

.user-navbar {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.user-navbar a {
  display: flex;
  align-items: center;
}
.user-navbar a:hover,
.user-navbar a:active,
.user-navbar a:visited {
}
.user-navbar .user-navbar__email {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #1d262d;
  margin-right: 1rem;
}
.user-navbar .user-navbar__avatar {
  border-radius: 4rem;
  max-width: 4rem;
  overflow: hidden;
}
.user-navbar .user-navbar__image {
  max-width: 100%;
}

/*
============
= DROPDOWN =
============
*/
.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: -17px;
  left: auto;
  right: 17px;
  transform: translate3d(12px, 60px, 0);
  padding: 0.5rem;
  margin-top: -4px;
  min-width: 10rem;
  border: 1px solid #1d262d;
  border-radius: 0.5rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0 0;
  color: #fff;
  background-color: #1d262d;
  background-clip: padding-box;
  font-size: 1.2rem;
  list-style: none;
  z-index: 9;
}

.dropdown-menu:before {
  transform: rotate(45deg) translate(-7px);
  border-left: 1px solid #1d262d;
  border-top: 1px solid #1d262d;
  right: 0.6rem;
  left: auto;
  color: #fff;
  background-color: #1d262d;
  content: '';
  position: absolute;
  top: 0;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.dropdown__item {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}
.dropdown__item:hover,
.dropdown__item:visited,
.dropdown__item:active {
  color: #fff;
  border-radius: 0.4rem;
  background-color: #F99122;
}

.dropdown__item svg {
  margin-right: 1rem;
}
.dropdown__divider {
  margin: 0.5rem 0;
  border-top: 1px solid #eee;
  height: 0;
  overflow: hidden;
}
