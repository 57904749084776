@font-face {
  font-family: 'mainFont';
  src: url('./assets/fonts/Gothic.ttf');
}
@font-face {
  font-family: 'mainFontBold';
  src: url('./assets/fonts/GothicBold.ttf');
}

html {
  font-size: 10px !important;
}

body {
  font-family: 'mainFont';
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
a:visited,
a:active {
  color: #000;
}

.container {
  display: grid;
  grid-template: 1fr / 1fr minmax(auto, 720px) 1fr;
  justify-content: center;
  align-content: center;
}

/*
============
=== TEXT ===
============
*/
.title {
  font-family: 'mainFontBold';
  font-size: 3.6rem;
  letter-spacing: 0;
  line-height: 4.4rem;
}
.text {
  font-family: 'mainFont';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}
.text-bold {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}
.text-featured {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}

/*
============
= PROGRESS =
============
*/
.progress-bar {
  width: 100%;
  height: 2px;
  background-color: #2B2A28 ;
  position: relative;
}
.progress-bar .progress-bar__status {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 20%;
  background-color: #DB3167;
}
.progress-bar .progress-bar__position {
  background: #ffffff;
  box-shadow: 0 0 32px 0 rgba(71, 90, 115, 0.4);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: none;
  position: absolute;
  top: -4px;
  left: auto;
  right: 0;
}

/*
============
== BUTTON ==
============
*/
.button__wrapper {
  grid-column: 2 / 3;
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: flex-end;
  align-content: flex-start;
  margin-top: 2rem;
}
.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button__link {
  background: #F99122;
  border-radius: 0.8rem;
  padding: 2.5rem;
  font-family: 'mainFontBold';
  font-size: 1.8rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  display: block;
  border: 0;
}
.button__link:hover,
.button__link:visited,
.button__link:active {
  color: #fff;
}
