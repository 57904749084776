/*
============
=== PAGE ===
============
*/
body.welcome {
  background: url('./../../../assets/img/bg_bienvenida.jpg') top center
    no-repeat;
  background-size: cover;
}

.welcome-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #2B2A28 ;
  border-radius: 0.8rem;
  color: white;
}
.welcome-content .welcome-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.welcome-content .welcome-content__text {
  margin-bottom: 1.4rem;
  color: #fff;
  text-align: center;
}
.welcome-content .welcome-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}
