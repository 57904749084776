.content-table {
  grid-column: 1 / 5;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.Component-horizontalScrollContainer-12 {
  border: 1px solid #2B2A28;
  border-radius: 0.5rem;
  font-family: 'mainFont' !important;
}

.MTableToolbar-actions-8 {
  border-radius: 2rem;
  background-color: #F99122;
  color: #fff !important;
}

.MTableToolbar-actions-8:hover {
  background-color: #F99122;
}

.MuiTableRow-root:nth-child(2n) {
  background-color: rgba(0, 216, 249, 0.1);
}

.MuiTableCell-root {
  font-size: 1.4rem !important;
  line-height: 1.9rem !important;
  font-family: 'mainFont' !important;
}

.MuiTableRow-root.MuiTableRow-head .MuiTableCell-head {
  color: #fff;
  background-color: #F99122;
  font-size: 1.6rem !important;
  font-family: 'mainFontBold' !important;
}

.MuiTableSortLabel-root:hover {
  color: #2B2A28 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiFormLabel-root.Mui-focused {
  color: #F99122 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #F99122 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 216, 249, 0.1) !important;
}

.MTablePaginationInner-root-14,
.MuiTypography-caption {
  font-size: 1.2rem !important;
}
