.menu__list {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.menu__item {
  display: flex;
  flex-flow: row;
  margin-bottom: 2rem;
  color: #2B2A28;
}
.menu__item a {
  color: #2B2A28;
  font-size: 2.4rem;
  line-height: 2.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.menu__item a svg {
  margin-right: 2rem;
  min-width: 25px;
  max-width: 25px;
}
.menu__item a:hover,
.menu__item.active a {
  color: #fff;
  background-color: #F99122;
  border-radius: 0.8rem;
}
