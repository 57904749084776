.sidebar {
  z-index: 2;
  background-color: #FFF;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 2rem;
}

.logo-container {
  max-width: 100%;
  display: flex;
  margin-bottom: 4rem;
}
.logo-container .logo {
  max-width: 100%;
}

.menu-container {
  display: flex;
  width: 100%;
}
