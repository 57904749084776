/*
============
=== PAGE ===
============
*/
body.legal {
  background: url('../../../assets/img/bg_aviso-legal.jpg') top center no-repeat;
  background-size: cover;
}

body.legal .container {
  grid-template: 1fr / 1fr minmax(auto, 947px) 1fr;
}

.legal-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #fff;
  border-radius: 0.8rem;
  color: #2B2A28;
}
.legal-content .legal-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.legal-content .legal-content__text {
  margin-bottom: 2rem;
  color: #2B2A28;
}
.legal-content .legal-content__text .text {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.legal-content .legal-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}
.legal-content .legal-content__text-featured .text-featured {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.4rem;
}
