/*
============
=== PAGE ===
============
*/
body.result {
  background: url('../../../assets/img/bg_arquetipo-explorador.jpg') top center no-repeat;
  background-size: cover;
}

body.result .container {
  display: grid;
  grid-template: repeat(2, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.result-title {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 4rem;
}

.result-content {
  display: grid;
  grid-template: repeat(5, minmax(auto, 84px)) / repeat(2, minmax(auto, 464px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}

/***********
*** BLOCK 1
***********/
.result__content {
  grid-column: 1 / 2;
  grid-row: 1 / 6;
  background-color: #2B2A28;
  border-radius: 0.8rem;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start; /*space-between ?*/
  text-align: center;
}

.result__avatar {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.result__avatar .title {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  color: #FFF;
}

.result__title {
  margin-bottom: 2.5rem;
  color: #2B2A28;
  background-color: #FFF;
  border-radius: 0.8rem;
  padding: 0;
}
.result__title .title {
  text-transform: uppercase;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1rem;
}
.result__title .subtitle {
  font-family: 'mainFont';
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem;
}
.result__title .subtitle .featured {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #2B2A28;
}

.result__text {
  margin-bottom: 2.5rem;
  color: #fff;
}
.result__text .text-featured {
  color: #2B2A28;
}
.result__text .text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'mainFontBold';
  text-align: left;
}

/***********
*** BLOCK 2
***********/
.result__statistics {
  grid-column: 2 / 3;
  grid-row: 1 / 6;
  background-color: #FFF;
  border-radius: 0.8rem;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start; /*space-between ?*/
  text-align: center;
  position: relative;
}
.result__statistics__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.result__statistics__title .statistics__title {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  color: #2B2A28 ;
}

.competence__statistics {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.property {
  width: 60%;
  color: #2B2A28;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.property__title {
  font-family: 'mainFontBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-transform: capitalize;
}
.property__index {
  color:#BBB;
}

.level {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
}
.level__value {
  font-family: 'mainFontBold';
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.result__graph {
  width: 100%;
  margin-top: 1rem;
}

.result-button__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.result-button__wrapper .question__button {
  margin-left: 0;
}


/*
============
== COLORS ==
============
*/
.novel_digital {
  background-color: #19bc9c;
  color: #FFF;
}
.explorador_digital {
  background-color: #3498db;
  color: #FFF;
}
.avanzado_digital {
  background-color: #9b59b6;
  color: #FFF;
}
.experto_digital {
  background-color: #DBD234;
  color: #FFF;
}
