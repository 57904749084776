.header-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 3rem;
}
.header-welcome img {
  max-width: 451px;
  max-height: 160px;
  height: auto;
}
.header-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 3rem;
}
