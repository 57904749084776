/*
============
=== CARD ===
============
*/
section.dashboard-form {
  grid-column: 1 / 5;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 2rem;
}
.form-header {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}
.form-header .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
}
.form-header .icon-section svg {
  color: #fff;
}
.form-header .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.form-header .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.form-header .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #2B2A28;
}

.question-form {
  box-sizing: border-box;
  font-family: 'mainFont';
  max-width: 95%;
  width: 95%;
  min-width: 400px;
}
.question-form .question-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}
.question-form .question-form__content .form__group {
  display: flex;
  flex-flow: column;
}
.question-form .question-form__content label {
  margin-bottom: 1rem;
  color: #F99122;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-family: 'mainFontBold';
}
.question-form .question-form__content .form__feedback {
  margin-bottom: 2rem;
}
.question-form .question-form__content .form__input,
.question-form .question-form__content .form__select,
.question-form .question-form__content .form__textarea {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
  line-height: 1.9rem;
}
.question-form .question-form__content .form__textarea {
  min-height: 5rem;
  max-height: 5rem;
}
.question-form .question-form__content .form__input:focus,
.question-form .question-form__content .form__textarea:focus,
.question-form .question-form__content .form__select:focus {
  border-color: #F99122;
}
.question-form .question-form__content .form__error {
  border-color: #d33;
}
.question-form .question-form__content .form__input:placeholder {
  color: #aaaaaa;
}
.question-form .question-form__content .form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.question-form .question-form__content .img-fluid {
  max-width: 15rem;
  max-height: 15rem;
  width: auto;
  height: auto;
  margin-bottom: 2rem;
}

.question-form .question-form__content .separator__answers {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(180deg, rgb(0, 216, 249) 0%, #F99122 100%);
  position: relative;
  border: none;
  margin: 2rem auto;
}
.question-form .question-form__content .form__answers__wrapper {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  margin: 1rem 0;
}
.question-form .question-form__content .form__answer__wrapper__title {
  margin: 1rem 0;
  color: #2B2A28;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-family: 'mainFontBold';
  text-transform: uppercase;
}
