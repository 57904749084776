/*
============
== COLORS ==
============
*/
.novel_digital {
  background-color: #19bc9c;
  color: #FFF;
}
.explorador_digital {
  background-color: #3498db;
  color: #FFF;
}
.avanzado_digital {
  background-color: #9b59b6;
  color: #FFF;
}
.experto_digital {
  background-color: #DBD234;
  color: #FFF;
}

.graph {
  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;
  height: 15rem;
  width: 100%;
}
.graph > div {
  display: flex;
  border-bottom: 1px solid #FFF;
}
.graph > div:last-child {
  border-bottom: none;
}

/* BAR CHART */
.graph .bar_chart__container {
  position: relative;
  width: calc(100% - 2rem);
  border-bottom: none;
  margin: 0 auto;
}
.graph .bar_chart__container > div {
  position: absolute;
  height: 15rem;
  width: calc(100% / 5);
  top: auto;
  bottom: -15rem;
} 
.graph .bar_chart__container > div:nth-child(1) {
  left: 0%;
} 
.graph .bar_chart__container > div:nth-child(2) {
  left: 20%;
} 
.graph .bar_chart__container > div:nth-child(3) {
  left: 40%;
} 
.graph .bar_chart__container > div:nth-child(4) {
  left: 60%;
} 
.graph .bar_chart__container > div:nth-child(5) {
  left: 80%;
} 
.graph .bar_chart__container .nobar {
  background-color: transparent;
}
.graph .bar_chart__container .bar {
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 auto;
  width: 80%;
}
.graph .bar_chart__container .number {
  margin-bottom: -1.5rem;
}
.graph .bar_chart__container .number p {
  font-family: 'mainFontBold';
  font-size: 1rem;
}

/* CHART NOTES */
.graph .notes_chart__container {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  border-top: 1px solid #CCC;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
}
.graph .notes_chart__container .notes_chart {
  font-family: 'mainFont';
  font-size: 1rem;
  line-height: 1.2rem;
  color: #2b2a2b;
}

.graph .notes_chart__container .notes_chart_color {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.graph .notes_chart__container .notes_chart .notes_chart__name{
  opacity: 0.5;
  text-transform: capitalize;
}
