/*
============
=== PAGE ===
============
*/
body.instructions {
  background: url('../../../assets/img/bg_instrucciones.jpg') top center
    no-repeat;
  background-size: cover;
}

body.instructions .container {
  grid-template: 1fr / 1fr minmax(auto, 947px) 1fr;
}

.instructions-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #fff;
  border-radius: 0.8rem;
  color: #000;
}
.instructions-content .instructions-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.instructions-content .instructions-content__text {
  margin-bottom: 1.4rem;
  color: #000;
}
.instructions-content .instructions-content__text-bold {
  margin-bottom: 1.4rem;
  color: #000;
}
.instructions-content .instructions-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}
.instructions-content .instructions-content__button {
  width: 100%;
}
.instructions-content__button .button__link {
  background-color: #DB3167;
}
