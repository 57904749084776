body.security {
  background: url('./../../../assets/img/bg_instrucciones.jpg') top center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form,
.login-form * {
  box-sizing: border-box;
  font-family: 'mainFont';
}

.login-form {
  max-width: 450px;
  min-width: 450px;
  width: 450px;
  margin: 0 auto;
}

.login-form__logo-container {
  padding: 3rem;
  background: transparent;
}

.login-form__logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
}

.login-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}

.login-form__header {
  margin-bottom: 3rem;
  text-align: center;
  color: #F99122;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  font-family: 'mainFontBold';
}

.login-form__input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}

.login-form__input:focus {
  border-color: #F99122;
}

.login-form__input::placeholder {
  color: #aaaaaa;
}

.login-form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-form__links {
  margin-top: 0;
  text-align: left;
}

.login-form__link {
  font-size: 1.6rem;
  color: #F99122;
  text-decoration: none;
}

.login-form__link:hover,
.login-form__link:active,
.login-form__link:visited {
  color: #F99122;
  text-decoration: underline;
}

.login-form__feedback {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
