/*
============
=== CARD ===
============
*/
section.dashboard-form {
  grid-column: 1 / 5;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 2rem;
}
.form-profile-header {
  display: flex;
  margin-bottom: 2rem;
}
.form-profile-header .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
}
.form-profile-header .icon-section svg {
  color: #fff;
}
.form-profile-header .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.form-profile-header .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.form-profile-header .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #2B2A28;
}

.reset-password-form {
  box-sizing: border-box;
  font-family: 'mainFont';
  max-width: 400px;
  min-width: 400px;
}
.reset-password-form .reset-password-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}
.reset-password-form .reset-password-form__content .form__group {
  display: flex;
  flex-flow: column;
}
.reset-password-form .reset-password-form__content .form__input {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}
.reset-password-form .reset-password-form__content .form__input:focus {
  border-color: #F99122;
}
.reset-password-form .reset-password-form__content .form__input:placeholder {
  color: #aaaaaa;
}
.reset-password-form .reset-password-form__content .form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.reset-password-form .reset-password-form__content .login-form__feedback {
  font-size: 1.6rem;
}
