.swal2-container {
  font-family: 'mainFont';
  color: #fff;
}

.swal2-modal {
  background-color: #1d262d;
  border-radius: 1rem;
}

.swal2-icon.swal2-warning {
  color: #fff;
  border-color: #fff;
}

.swal2-title {
  color: #F99122;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.swal2-content {
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.swal2-actions .swal2-confirm,
.swal2-actions .swal2-cancel {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: 'mainFont';
}

.swal2-actions .swal2-confirm:active,
.swal2-actions .swal2-confirm:hover,
.swal2-actions .swal2-confirm:focus {
  border: none;
  box-shadow: none;
  color: #fff !important;
  background-color: #F99122;
}

.swal2-actions .swal2-cancel:active,
.swal2-actions .swal2-cancel:hover,
.swal2-actions .swal2-cancel:focus {
  border: none;
  box-shadow: none;
  color: #fff;
  background-image: linear-gradient(180deg, #d33 0%, red 100%) !important;
}
