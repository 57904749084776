@font-face {
  font-family: 'mainFont';
  src: url(/static/media/Gothic.8917856b.ttf);
}
@font-face {
  font-family: 'mainFontBold';
  src: url(/static/media/GothicBold.ae704b11.ttf);
}

html {
  font-size: 10px !important;
}

body {
  font-family: 'mainFont';
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  /* position: fixed; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
a:visited,
a:active {
  color: #000;
}

.container {
  display: grid;
  grid-template: 1fr / 1fr minmax(auto, 720px) 1fr;
  justify-content: center;
  align-content: center;
}

/*
============
=== TEXT ===
============
*/
.title {
  font-family: 'mainFontBold';
  font-size: 3.6rem;
  letter-spacing: 0;
  line-height: 4.4rem;
}
.text {
  font-family: 'mainFont';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}
.text-bold {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}
.text-featured {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
}

/*
============
= PROGRESS =
============
*/
.progress-bar {
  width: 100%;
  height: 2px;
  background-color: #2B2A28 ;
  position: relative;
}
.progress-bar .progress-bar__status {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 20%;
  background-color: #DB3167;
}
.progress-bar .progress-bar__position {
  background: #ffffff;
  box-shadow: 0 0 32px 0 rgba(71, 90, 115, 0.4);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: none;
  position: absolute;
  top: -4px;
  left: auto;
  right: 0;
}

/*
============
== BUTTON ==
============
*/
.button__wrapper {
  grid-column: 2 / 3;
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: flex-end;
  align-content: flex-start;
  margin-top: 2rem;
}
.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button__link {
  background: #F99122;
  border-radius: 0.8rem;
  padding: 2.5rem;
  font-family: 'mainFontBold';
  font-size: 1.8rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  display: block;
  border: 0;
}
.button__link:hover,
.button__link:visited,
.button__link:active {
  color: #fff;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.menu__list {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.menu__item {
  display: flex;
  flex-flow: row;
  margin-bottom: 2rem;
  color: #2B2A28;
}
.menu__item a {
  color: #2B2A28;
  font-size: 2.4rem;
  line-height: 2.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.menu__item a svg {
  margin-right: 2rem;
  min-width: 25px;
  max-width: 25px;
}
.menu__item a:hover,
.menu__item.active a {
  color: #fff;
  background-color: #F99122;
  border-radius: 0.8rem;
}

.sidebar {
  z-index: 2;
  background-color: #FFF;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 2rem;
}

.logo-container {
  max-width: 100%;
  display: flex;
  margin-bottom: 4rem;
}
.logo-container .logo {
  max-width: 100%;
}

.menu-container {
  display: flex;
  width: 100%;
}

/*
============
== NAVBAR ==
============
*/
.header-navbar {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
}

.user-navbar {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.user-navbar a {
  display: flex;
  align-items: center;
}
.user-navbar a:hover,
.user-navbar a:active,
.user-navbar a:visited {
}
.user-navbar .user-navbar__email {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #1d262d;
  margin-right: 1rem;
}
.user-navbar .user-navbar__avatar {
  border-radius: 4rem;
  max-width: 4rem;
  overflow: hidden;
}
.user-navbar .user-navbar__image {
  max-width: 100%;
}

/*
============
= DROPDOWN =
============
*/
.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: -17px;
  left: auto;
  right: 17px;
  transform: translate3d(12px, 60px, 0);
  padding: 0.5rem;
  margin-top: -4px;
  min-width: 10rem;
  border: 1px solid #1d262d;
  border-radius: 0.5rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0 0;
  color: #fff;
  background-color: #1d262d;
  background-clip: padding-box;
  font-size: 1.2rem;
  list-style: none;
  z-index: 9;
}

.dropdown-menu:before {
  transform: rotate(45deg) translate(-7px);
  border-left: 1px solid #1d262d;
  border-top: 1px solid #1d262d;
  right: 0.6rem;
  left: auto;
  color: #fff;
  background-color: #1d262d;
  content: '';
  position: absolute;
  top: 0;
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  z-index: 10;
  box-sizing: border-box;
}

.dropdown__item {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}
.dropdown__item:hover,
.dropdown__item:visited,
.dropdown__item:active {
  color: #fff;
  border-radius: 0.4rem;
  background-color: #F99122;
}

.dropdown__item svg {
  margin-right: 1rem;
}
.dropdown__divider {
  margin: 0.5rem 0;
  border-top: 1px solid #eee;
  height: 0;
  overflow: hidden;
}

body.security {
  background: url(/static/media/bg_instrucciones.905bb5e4.jpg) top center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form,
.login-form * {
  box-sizing: border-box;
  font-family: 'mainFont';
}

.login-form {
  max-width: 450px;
  min-width: 450px;
  width: 450px;
  margin: 0 auto;
}

.login-form__logo-container {
  padding: 3rem;
  background: transparent;
}

.login-form__logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
}

.login-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}

.login-form__header {
  margin-bottom: 3rem;
  text-align: center;
  color: #F99122;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  font-family: 'mainFontBold';
}

.login-form__input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}

.login-form__input:focus {
  border-color: #F99122;
}

.login-form__input::-webkit-input-placeholder {
  color: #aaaaaa;
}

.login-form__input:-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::placeholder {
  color: #aaaaaa;
}

.login-form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-form__links {
  margin-top: 0;
  text-align: left;
}

.login-form__link {
  font-size: 1.6rem;
  color: #F99122;
  text-decoration: none;
}

.login-form__link:hover,
.login-form__link:active,
.login-form__link:visited {
  color: #F99122;
  text-decoration: underline;
}

.login-form__feedback {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

body.security {
  background: url(/static/media/bg_instrucciones.905bb5e4.jpg) top center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form,
.login-form * {
  box-sizing: border-box;
  font-family: 'mainFont';
}

.login-form {
  max-width: 450px;
  min-width: 450px;
  width: 450px;
  margin: 0 auto;
}

.login-form__logo-container {
  padding: 3rem;
  background: transparent;
}

.login-form__logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
}

.login-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}

.login-form__header {
  margin-bottom: 3rem;
  text-align: center;
  color: #F99122;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  font-family: 'mainFontBold';
}

.login-form__input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}

.login-form__input:focus {
  border-color: #F99122;
}

.login-form__input::-webkit-input-placeholder {
  color: #aaaaaa;
}

.login-form__input:-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::placeholder {
  color: #aaaaaa;
}

.login-form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-form__links {
  margin-top: 0;
  text-align: left;
}

.login-form__link {
  font-size: 1.6rem;
  color: #F99122;
  text-decoration: none;
}

.login-form__link:hover,
.login-form__link:active,
.login-form__link:visited {
  color: #F99122;
  text-decoration: underline;
}

.login-form__feedback {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

body.security {
  background: url(/static/media/bg_instrucciones.905bb5e4.jpg) top center
    no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form,
.login-form * {
  box-sizing: border-box;
  font-family: 'mainFont';
}

.login-form {
  max-width: 450px;
  min-width: 450px;
  width: 450px;
  margin: 0 auto;
}

.login-form__logo-container {
  padding: 3rem;
  background: transparent;
}

.login-form__logo {
  display: block;
  max-width: 250px;
  margin: 0 auto;
}

.login-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}

.login-form__header {
  margin-bottom: 3rem;
  text-align: center;
  color: #F99122;
  font-size: 2.2rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  font-family: 'mainFontBold';
}

.login-form__input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}

.login-form__input:focus {
  border-color: #F99122;
}

.login-form__input::-webkit-input-placeholder {
  color: #aaaaaa;
}

.login-form__input:-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::-ms-input-placeholder {
  color: #aaaaaa;
}

.login-form__input::placeholder {
  color: #aaaaaa;
}

.login-form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-form__links {
  margin-top: 0;
  text-align: left;
}

.login-form__link {
  font-size: 1.6rem;
  color: #F99122;
  text-decoration: none;
}

.login-form__link:hover,
.login-form__link:active,
.login-form__link:visited {
  color: #F99122;
  text-decoration: underline;
}

.login-form__feedback {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.header-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 3rem;
}
.header-welcome img {
  max-width: 451px;
  max-height: 160px;
  height: auto;
}
.header-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  margin-bottom: 3rem;
}


/*
============
== FOOTER ==
============
*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2rem;
}
.footer .footer-item {
}

.footer .footer__links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer .footer__links .footer__copyright p {
  font-family: 'mainFont';
  font-size: 1.2rem;
  color: #475a73;
  letter-spacing: 0;
}
.footer .footer__links .footer__copyright p::after {
  content: '|';
  margin: 0 1rem 0 1rem;
}
.footer .footer__links .footer__copyright span {
  font-family: 'mainFontBold';  
}
.footer .footer__links .footer__legal a,
.footer .footer__links .footer__legal a:hover,
.footer .footer__links .footer__legal a:visited,
.footer .footer__links .footer__legal a:active {
  font-family: 'mainFont';
  font-size: 1.2rem;
  color: #475a73;
  letter-spacing: 0;
  display: block;
}
.footer .footer__logo {
}
.footer .footer__logo img {
}

/*
============
=== PAGE ===
============
*/
body.welcome {
  background: url(/static/media/bg_bienvenida.9204c7ba.jpg) top center
    no-repeat;
  background-size: cover;
}

.welcome-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #2B2A28 ;
  border-radius: 0.8rem;
  color: white;
}
.welcome-content .welcome-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.welcome-content .welcome-content__text {
  margin-bottom: 1.4rem;
  color: #fff;
  text-align: center;
}
.welcome-content .welcome-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}

/*
============
=== PAGE ===
============
*/
body.legal {
  background: url(/static/media/bg_aviso-legal.fe7d77f1.jpg) top center no-repeat;
  background-size: cover;
}

body.legal .container {
  grid-template: 1fr / 1fr minmax(auto, 947px) 1fr;
}

.legal-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #fff;
  border-radius: 0.8rem;
  color: #2B2A28;
}
.legal-content .legal-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.legal-content .legal-content__text {
  margin-bottom: 2rem;
  color: #2B2A28;
}
.legal-content .legal-content__text .text {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.legal-content .legal-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}
.legal-content .legal-content__text-featured .text-featured {
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

/*
============
=== PAGE ===
============
*/
body.instructions {
  background: url(/static/media/bg_instrucciones.905bb5e4.jpg) top center
    no-repeat;
  background-size: cover;
}

body.instructions .container {
  grid-template: 1fr / 1fr minmax(auto, 947px) 1fr;
}

.instructions-content {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 4rem 6rem 3rem 6rem;
  background: #fff;
  border-radius: 0.8rem;
  color: #000;
}
.instructions-content .instructions-content__title {
  margin-bottom: 2rem;
  color: #F99122;
}
.instructions-content .instructions-content__text {
  margin-bottom: 1.4rem;
  color: #000;
}
.instructions-content .instructions-content__text-bold {
  margin-bottom: 1.4rem;
  color: #000;
}
.instructions-content .instructions-content__text-featured {
  margin-bottom: 1.4rem;
  color: #DB3167;
}
.instructions-content .instructions-content__button {
  width: 100%;
}
.instructions-content__button .button__link {
  background-color: #DB3167;
}

/*
============
=== PAGE ===
============
*/

body.questions .container {
  display: grid;
  grid-template: repeat(3, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.questions-content {
  display: grid;
  grid-template: repeat(2, minmax(auto, 226px)) / repeat(4, minmax(auto, 226px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.6rem;
}

.question__content {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  background: #ffffff;
  border-radius: 0.8rem;
  padding: 5.6rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: space-between;
}
.question__position {
  background-color: #DB3167;
  border-radius: 0.8rem;
  position: absolute;
  top: 5.6rem;
  left: -4rem;
  padding: 2.2rem;
}
.question__number {
  font-family: 'mainFont';
  font-size: 2.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.question__title {
  font-family: 'mainFontBold';
  font-size: 3.2rem;
  color: #F99122;
  letter-spacing: 0;
  text-align: right;
  line-height: 3.9rem;
}
.question__text {
  font-family: 'mainFontBold';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-align: right;
  line-height: 1.7rem;
}

.answer {
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(71, 90, 115, 0.4);
  border-radius: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;
}
.answer:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 20%;
  transition: transform 0.3s ease-out;
}
.answer:hover:before {
  transform: scaleX(1);
}
.answer:hover .answer__text {
  color: #fff;
}
.answer.multi:after {
  content: url(/static/media/check-desmarcado.4a3c87d4.svg);
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 10%;
  left: auto;
  right: 10%;
}
.multi:hover:after {
  content: url(/static/media/check-marcado.881243ef.svg);
}
.multi.clicked:after {
  content: url(/static/media/check-marcado.881243ef.svg);
}

.answer__choice {
  width: 20%;
  height: 100%;
}
.answer__text {
  padding-left: 2%;
  padding-right: 10%;
  width: 80%;
  font-family: 'mainFont';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  line-height: 1.7rem;
}
.answer.clicked .answer__text {
  color: #fff;
}

.answer__a {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}
.answer__a .answer__choice {
  background: url(/static/media/respuesta-a.8f2428de.svg) center left no-repeat;
}
.answer__a.clicked {
  background-color: #19bc9c;
}
.answer__a:before {
  background-color: #19bc9c;
}

.answer__b {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}
.answer__b .answer__choice {
  background: url(/static/media/respuesta-b.3d6506e0.svg) center left no-repeat;
}
.answer__b.clicked {
  background-color: #3498db;
}
.answer__b:before {
  background-color: #3498db;
}

.answer__c {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}
.answer__c .answer__choice {
  background: url(/static/media/respuesta-c.b94bb82c.svg) center left no-repeat;
}
.answer__c.clicked {
  background-color: #9b59b6;
}
.answer__c:before {
  background-color: #9b59b6;
}

.answer__d {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}
.answer__d .answer__choice {
  background: url(/static/media/respuesta-d.1f0b4d68.svg) center left no-repeat;
}
.answer__d.clicked {
  background-color: #DBD234;
}
.answer__d:before {
  background-color: #DBD234;
}

.questions-feedback {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template: repeat(1, auto) / repeat(5, minmax(auto, 226px));
}
.question__feedback {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  background-color: #D21309;
  border-radius: 0.8rem;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.feedback__text {
  font-family: 'mainFontBold';
  font-size: 1.8rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.question__button {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
  margin-left: 2rem;
  display: flex;
}

.question__button .button {
  margin-right: 0;
  width: 100%;
}

.question__button .button .button__link {
  width: 100%;
}

/*
============
=== PAGE ===
============
*/
body.arquetipo {
  background: url(/static/media/bg_arquetipo-explorador.5998fd1a.jpg) top center no-repeat;
  background-size: cover;
}

body.arquetipo .container {
  display: grid;
  grid-template: repeat(2, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.arquetipo-title {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #FFF;
  color: #F99122;
  text-align: center;
  margin-bottom: 4rem;
  border-radius: 0.8rem;
  padding: 2rem;
}
.arquetipo-title .title {
  color: #F99122;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.arquetipo-content {
  display: grid;
  grid-template: repeat(5, minmax(auto, 84px)) / repeat(2, minmax(auto, 464px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}

.arquetipo__content {
  grid-column: 1 / 3;
  grid-row: 1 / 6;
  background-color: #2B2A28;
  border-radius: 0.8rem;
  padding: 5.6rem 5.6rem 12rem 5.6rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.arquetipo__avatar {
  margin-top: -10rem;
  margin-bottom: 3rem;
}

.arquetipo__title {
  margin-bottom: 1.5rem;
}
.arquetipo__title .title {
  color: #F99122;
  text-transform: uppercase;
}

.arquetipo__text {
  margin-bottom: 1.5rem;
  color: #fff;
}
.arquetipo__text .text-featured {
  color: #DB3167;
}
.arquetipo__text .text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'mainFontBold';
}
.arquetipo .button__container {
  display: grid;
  grid-template: repeat(1, minmax(auto, 84px)) / repeat(4, minmax(auto, 224px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}
.arquetipo .arquetipo__feedback {
  background-color: transparent;
  grid-column: 1 / 5;
  border-radius: 0;
  text-align: right;
  padding-top: 2.5rem;
  padding-bottom: 0;
}
.arquetipo .arquetipo__feedback .feedback__text {
  font-family: 'mainFontBold';
  font-size: 1.4rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-align: right;
  display: flex;
  justify-content: center;
  align-content: center;
}

/*
============
== COLORS ==
============
*/
.novel_digital {
  background-color: #19bc9c;
  color: #FFF;
}
.explorador_digital {
  background-color: #3498db;
  color: #FFF;
}
.avanzado_digital {
  background-color: #9b59b6;
  color: #FFF;
}
.experto_digital {
  background-color: #DBD234;
  color: #FFF;
}

.graph {
  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;
  height: 15rem;
  width: 100%;
}
.graph > div {
  display: flex;
  border-bottom: 1px solid #FFF;
}
.graph > div:last-child {
  border-bottom: none;
}

/* BAR CHART */
.graph .bar_chart__container {
  position: relative;
  width: calc(100% - 2rem);
  border-bottom: none;
  margin: 0 auto;
}
.graph .bar_chart__container > div {
  position: absolute;
  height: 15rem;
  width: calc(100% / 5);
  top: auto;
  bottom: -15rem;
} 
.graph .bar_chart__container > div:nth-child(1) {
  left: 0%;
} 
.graph .bar_chart__container > div:nth-child(2) {
  left: 20%;
} 
.graph .bar_chart__container > div:nth-child(3) {
  left: 40%;
} 
.graph .bar_chart__container > div:nth-child(4) {
  left: 60%;
} 
.graph .bar_chart__container > div:nth-child(5) {
  left: 80%;
} 
.graph .bar_chart__container .nobar {
  background-color: transparent;
}
.graph .bar_chart__container .bar {
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 auto;
  width: 80%;
}
.graph .bar_chart__container .number {
  margin-bottom: -1.5rem;
}
.graph .bar_chart__container .number p {
  font-family: 'mainFontBold';
  font-size: 1rem;
}

/* CHART NOTES */
.graph .notes_chart__container {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  border-top: 1px solid #CCC;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
}
.graph .notes_chart__container .notes_chart {
  font-family: 'mainFont';
  font-size: 1rem;
  line-height: 1.2rem;
  color: #2b2a2b;
}

.graph .notes_chart__container .notes_chart_color {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.graph .notes_chart__container .notes_chart .notes_chart__name{
  opacity: 0.5;
  text-transform: capitalize;
}

/*
============
=== PAGE ===
============
*/
body.result {
  background: url(/static/media/bg_arquetipo-explorador.5998fd1a.jpg) top center no-repeat;
  background-size: cover;
}

body.result .container {
  display: grid;
  grid-template: repeat(2, auto) / minmax(auto, 946px);
  justify-content: center;
  align-content: center;
  grid-gap: 4rem;
}

.result-title {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 4rem;
}

.result-content {
  display: grid;
  grid-template: repeat(5, minmax(auto, 84px)) / repeat(2, minmax(auto, 464px));
  justify-content: center;
  align-content: center;
  grid-gap: 1.3rem 1.1rem;
}

/***********
*** BLOCK 1
***********/
.result__content {
  grid-column: 1 / 2;
  grid-row: 1 / 6;
  background-color: #2B2A28;
  border-radius: 0.8rem;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start; /*space-between ?*/
  text-align: center;
}

.result__avatar {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.result__avatar .title {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  color: #FFF;
}

.result__title {
  margin-bottom: 2.5rem;
  color: #2B2A28;
  background-color: #FFF;
  border-radius: 0.8rem;
  padding: 0;
}
.result__title .title {
  text-transform: uppercase;
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 1rem;
}
.result__title .subtitle {
  font-family: 'mainFont';
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem;
}
.result__title .subtitle .featured {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #2B2A28;
}

.result__text {
  margin-bottom: 2.5rem;
  color: #fff;
}
.result__text .text-featured {
  color: #2B2A28;
}
.result__text .text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: 'mainFontBold';
  text-align: left;
}

/***********
*** BLOCK 2
***********/
.result__statistics {
  grid-column: 2 / 3;
  grid-row: 1 / 6;
  background-color: #FFF;
  border-radius: 0.8rem;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start; /*space-between ?*/
  text-align: center;
  position: relative;
}
.result__statistics__title {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.result__statistics__title .statistics__title {
  font-family: 'mainFontBold';
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
  color: #2B2A28 ;
}

.competence__statistics {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.property {
  width: 60%;
  color: #2B2A28;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.property__title {
  font-family: 'mainFontBold';
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #2B2A28;
  letter-spacing: 0;
  text-transform: capitalize;
}
.property__index {
  color:#BBB;
}

.level {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
}
.level__value {
  font-family: 'mainFontBold';
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.result__graph {
  width: 100%;
  margin-top: 1rem;
}

.result-button__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.result-button__wrapper .question__button {
  margin-left: 0;
}


/*
============
== COLORS ==
============
*/
.novel_digital {
  background-color: #19bc9c;
  color: #FFF;
}
.explorador_digital {
  background-color: #3498db;
  color: #FFF;
}
.avanzado_digital {
  background-color: #9b59b6;
  color: #FFF;
}
.experto_digital {
  background-color: #DBD234;
  color: #FFF;
}

/*
============
=== PAGE ===
============
*/
.dashboard {
  font-family: 'mainFont';
  color: #000;
  background-color: #eee;
  display: grid;
  grid-template: 1fr / minmax(auto, 350px) minmax(auto, 1fr);
  justify-content: flex-start;
  align-content: flex-start;
  grid-gap: 0;
  min-height: 100vh;
}

.content {
  background-color: transparent;
  display: grid;
  grid-template: minmax(auto, 65px) / repeat(4, minmax(auto, 1fr));
  justify-content: flex-start;
  align-content: flex-start;
  grid-gap: 2rem;
  padding: 2rem;
}

/*
============
=== CARD ===
============
*/
section.dashboard-export {
  grid-column: 1 / 3;
}
.card {
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
  cursor: pointer;
}
.card .icon-section svg {
  color: #fff;
}
.card .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.card .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.card .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #2B2A28;
}

/*
============
=== CARD ===
============
*/
section.dashboard-form {
  grid-column: 1 / 5;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 2rem;
}
.form-profile-header {
  display: flex;
  margin-bottom: 2rem;
}
.form-profile-header .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
}
.form-profile-header .icon-section svg {
  color: #fff;
}
.form-profile-header .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.form-profile-header .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.form-profile-header .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #2B2A28;
}

.reset-password-form {
  box-sizing: border-box;
  font-family: 'mainFont';
  max-width: 400px;
  min-width: 400px;
}
.reset-password-form .reset-password-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}
.reset-password-form .reset-password-form__content .form__group {
  display: flex;
  flex-flow: column;
}
.reset-password-form .reset-password-form__content .form__input {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
}
.reset-password-form .reset-password-form__content .form__input:focus {
  border-color: #F99122;
}
.reset-password-form .reset-password-form__content .form__input:placeholder {
  color: #aaaaaa;
}
.reset-password-form .reset-password-form__content .form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.reset-password-form .reset-password-form__content .login-form__feedback {
  font-size: 1.6rem;
}

.content-table {
  grid-column: 1 / 5;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.8rem;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.Component-horizontalScrollContainer-12 {
  border: 1px solid #2B2A28;
  border-radius: 0.5rem;
  font-family: 'mainFont' !important;
}

.MTableToolbar-actions-8 {
  border-radius: 2rem;
  background-color: #F99122;
  color: #fff !important;
}

.MTableToolbar-actions-8:hover {
  background-color: #F99122;
}

.MuiTableRow-root:nth-child(2n) {
  background-color: rgba(0, 216, 249, 0.1);
}

.MuiTableCell-root {
  font-size: 1.4rem !important;
  line-height: 1.9rem !important;
  font-family: 'mainFont' !important;
}

.MuiTableRow-root.MuiTableRow-head .MuiTableCell-head {
  color: #fff;
  background-color: #F99122;
  font-size: 1.6rem !important;
  font-family: 'mainFontBold' !important;
}

.MuiTableSortLabel-root:hover {
  color: #2B2A28 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiFormLabel-root.Mui-focused {
  color: #F99122 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #F99122 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 216, 249, 0.1) !important;
}

.MTablePaginationInner-root-14,
.MuiTypography-caption {
  font-size: 1.2rem !important;
}

.swal2-container {
  font-family: 'mainFont';
  color: #fff;
}

.swal2-modal {
  background-color: #1d262d;
  border-radius: 1rem;
}

.swal2-icon.swal2-warning {
  color: #fff;
  border-color: #fff;
}

.swal2-title {
  color: #F99122;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.swal2-content {
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.swal2-actions .swal2-confirm,
.swal2-actions .swal2-cancel {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-family: 'mainFont';
}

.swal2-actions .swal2-confirm:active,
.swal2-actions .swal2-confirm:hover,
.swal2-actions .swal2-confirm:focus {
  border: none;
  box-shadow: none;
  color: #fff !important;
  background-color: #F99122;
}

.swal2-actions .swal2-cancel:active,
.swal2-actions .swal2-cancel:hover,
.swal2-actions .swal2-cancel:focus {
  border: none;
  box-shadow: none;
  color: #fff;
  background-image: linear-gradient(180deg, #d33 0%, red 100%) !important;
}

/*
============
=== CARD ===
============
*/
section.dashboard-form {
  grid-column: 1 / 5;
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 2rem;
}
.form-header {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
}
.form-header .icon-section {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F99122;
  margin-right: 2rem;
}
.form-header .icon-section svg {
  color: #fff;
}
.form-header .title-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}
.form-header .title-section .title-section__title {
  text-transform: uppercase;
  font-family: 'mainFontBold';
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #1d262d;
}
.form-header .title-section .title-section__description {
  font-family: 'mainFontBold';
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #2B2A28;
}

.question-form {
  box-sizing: border-box;
  font-family: 'mainFont';
  max-width: 95%;
  width: 95%;
  min-width: 400px;
}
.question-form .question-form__content {
  padding: 3rem 3rem 8rem 3rem;
  background: #1d262d;
  position: relative;
  display: flex;
  flex-flow: column;
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
}
.question-form .question-form__content .form__group {
  display: flex;
  flex-flow: column;
}
.question-form .question-form__content label {
  margin-bottom: 1rem;
  color: #F99122;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-family: 'mainFontBold';
}
.question-form .question-form__content .form__feedback {
  margin-bottom: 2rem;
}
.question-form .question-form__content .form__input,
.question-form .question-form__content .form__select,
.question-form .question-form__content .form__textarea {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.8rem;
  border: 2px solid #dddddd;
  background: #ffffff;
  outline: none;
  transition: border-color 0.5s;
  color: #2B2A28;
  font-size: 1.6rem;
  line-height: 1.9rem;
}
.question-form .question-form__content .form__textarea {
  min-height: 5rem;
  max-height: 5rem;
}
.question-form .question-form__content .form__input:focus,
.question-form .question-form__content .form__textarea:focus,
.question-form .question-form__content .form__select:focus {
  border-color: #F99122;
}
.question-form .question-form__content .form__error {
  border-color: #d33;
}
.question-form .question-form__content .form__input:placeholder {
  color: #aaaaaa;
}
.question-form .question-form__content .form__button {
  padding: 1rem 2rem;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: -2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.question-form .question-form__content .img-fluid {
  max-width: 15rem;
  max-height: 15rem;
  width: auto;
  height: auto;
  margin-bottom: 2rem;
}

.question-form .question-form__content .separator__answers {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(180deg, rgb(0, 216, 249) 0%, #F99122 100%);
  position: relative;
  border: none;
  margin: 2rem auto;
}
.question-form .question-form__content .form__answers__wrapper {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  margin: 1rem 0;
}
.question-form .question-form__content .form__answer__wrapper__title {
  margin: 1rem 0;
  color: #2B2A28;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-family: 'mainFontBold';
  text-transform: uppercase;
}

